
import { defineComponent, ref, onMounted, computed } from "vue";
import { MenuComponent } from "@/assets/ts/components";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useStore } from "vuex";
import MasterService from "@/core/services/car/MasterService";
import { roles } from "@/store/stateless/store";

interface NotificationBodyParam {
  per_page: string;
}

interface IPayload {
  per_page: number | string;
  page: number;
}


export default defineComponent({
  name: "contact-us-list",
  components: {
    // InvoiceAction,
    // Overlay,
  },
  data() {
    return {
      // opened: false,
      // visible: false,
    };
  },
  setup() {
    // let customers_obj = ref([]);
    const store = useStore();
    let exportUrl = ref<string>("");
    let bodyParameters = ref<IPayload>({
      per_page: 50,
      page: 1
    });


    onMounted(() => {
      if(!roles.inquireContactUs.includes(roles.userId())) window.location.href = '/#/404'
      
      
      MenuComponent.reinitialization();

      setCurrentPageBreadcrumbs("Contact Us List", ["Inquires"]);
      // API Action to get customers list
      getContactUs(bodyParameters);
    });

    const getPaginationUrl = (index) => {
      window.scrollTo({ top: 0, behavior: 'smooth' })
      let pagenationUrl = index.split("=");
      bodyParameters.value.page = pagenationUrl[1];
      getContactUs(bodyParameters);
    };

    const onPerPageChange = (event) => {
      window.scrollTo({ top: 0, behavior: 'smooth' })
      bodyParameters.value.per_page = event.target.value;
      getContactUs(bodyParameters);
    };

    const exportContactUsRequest = () => {
      Promise.all([MasterService.exportContactUsRequest()]).then((data) => {
        let exportData = computed(() => {
          return store.getters.getExportData;
        });
        exportUrl.value = exportData.value;
      });
    };

    let disableSaveButton = computed(() => {
      return store.getters.getDisableButtonStatus;
    });

    const loading = ref(false);
    const getContactUs = (bodyParameters) => {
      loading.value = false;
      MasterService.getContactUs(bodyParameters.value)
        .then((data)=>{
        loading.value=true;
      });
    };

    const search = ref<string>("");

    return {
      roles,
      search,
      onPerPageChange,
      getPaginationUrl,
      bodyParameters,
      exportUrl,
      disableSaveButton,
      exportContactUsRequest,
      loading
    };
  },
});
